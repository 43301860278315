import http from "../http";
import axios from "axios";


/**
 * 职业装/零剪-分页查询滞销库存看板
 */
// export const businessunsserch = (currentPage, pageSize, data) => {
//   return http({
//     method: "post",
//     url: process.env.VUE_APP_INVENTORY_URL +`unsalable/v1/unsalabledashboard/search?currentPage=${currentPage}&pageSize=${pageSize}`,
//     data
//   });
// };

 
/**
 * 物料属性
 */
export const fabrictypeMaterialproperties= () => {
  let res ={}
  res.data=[
    {
        "name": "新品",
        "index": "newProduct"
    },
    {
        "name": "常规",
        "index": "routine"
    },
    {
        "name": "清货",
        "index": "clearGoods"
    },
    {
        "name": "清补",
        "index": "clearanceReplenishment"
    },
    {
        "name": "清货延续",
        "index": "subsequentClearance"
    }
  ]
  res.code='0'
  // return http({
  //   method: "get",
  //   url: process.env.VUE_APP_INFRASTRUCTURE_URL+ `/infrastructure/v1/constants/dicts?dictIndex=MaterialProperties`
  // });
  return res
};

/**
 * 工厂季
 */
export const fabrictypeSaleSeasou = () => {
    let res = {}
    res.data = [
      { "name": "S14", "index": "3" },
      { "name": "S15", "index": "4" },
      { "name": "S16", "index": "5" },
      { "name": "S17", "index": "6" },
      { "name": "S18", "index": "7" },
      { "name": "S19", "index": "1" },
      { "name": "S20", "index": "2" },
      { "name": "S21", "index": "18" },
      { "name": "S22", "index": "19" },
      { "name": "S23", "index": "21" },
      { "name": "S24", "index": "23" },
      { "name": "W10/11", "index": "8" },
      { "name": "W13/14", "index": "9" },
      { "name": "W14/15", "index": "10" },
      { "name": "W15/16", "index": "11" },
      { "name": "W16/17", "index": "12" },
      { "name": "W17/18", "index": "13" },
      { "name": "W18/19", "index": "14" },
      { "name": "W19/20", "index": "16" },
      { "name": "W20/21", "index": "15" },
      { "name": "W21/22", "index": "17" },
      { "name": "W22/23", "index": "20" },
      { "name": "W23/24", "index": "22" },
      { "name": "W24/25", "index": "24" }
    ]
    res.code = '0'
 
  // return http({
  //   method: "get",
  //   url: process.env.VUE_APP_INFRASTRUCTURE_URL+ `/infrastructure/v1/constants/dicts?dictIndex=SaleSeasou`
  // });
  return res
};

/**
 * 分页查询滞销看板
 */
export const businessunsserch = (currentPage, pageSize, data) => {
  return http({
    method: "post",
    url: process.env.VUE_APP_SCM_URL +`unsalaMaterial/v1/queryUnsalebleMaterialPage?currentPage=${currentPage}&pageSize=${pageSize}`,
    data
  });
};
/**
 * 获取供应商列表字典
 */
export const supplierDictList = () => {
  return http({
    method: "get",
    url: process.env.VUE_APP_SUPPLIER_URL + `supplier/v1/supplierCompanys/dict`
  });
};